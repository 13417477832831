import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/pmaxh/Documents/node-projects/website/src/components/page-layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I used `}<a parentName="p" {...{
        "href": "https://github.com/jeremyletang/rust-sfml"
      }}>{`rust-sfml`}</a>{` to create a simple platformer game. Multithreading is used to run the physics and drawing loops independently of each other.`}</p>
    <h2>{`Demo`}</h2>
    <Video src="/videos/platformer%20game%20demo.mp4" mdxType="Video" />
    <h2>{`Source`}</h2>
    <p>{`The source for my rhythm game is available `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/rust-learning/tree/RhythmGame"
      }}>{`on Github`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      